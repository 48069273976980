import { useContext, useEffect, useState } from "react";
import { ProcessFlowContext } from "../../context/context";
import PageTemplate from "../../components/PageTemplate";
import Button from "../../components/button/Button";
import { api } from "../../services";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { LoadingSpinner } from "../../components/loadingSpinner/LoadingSpinner";
import { refreshToken } from "../../hooks/TokenIsExpired";
dayjs.extend(utc);
export const OrderSummary = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(ProcessFlowContext);
  const itemsToOrder = !!state.itemsToOrder && !!state.itemsToOrder.length ? state.itemsToOrder : JSON.parse(localStorage.getItem("itemsToOrder"));
  const street = state.street || JSON.parse(localStorage.getItem("street"));
  const apartment = state.apartment || JSON.parse(localStorage.getItem("apartment"));
  const city = state.city || JSON.parse(localStorage.getItem("city"));
  const userState = state.userState || JSON.parse(localStorage.getItem("userState"));
  const zipCode = state.zipCode || JSON.parse(localStorage.getItem("zipCode"));
  const selectedDeliveryDate = state.selectedDeliveryDate || localStorage.getItem("selectedDeliveryDate");
  const regularItemsOrder = state.items.filter((item) => item.checked && !item.isTank);

  const [pageLoaded, setPageLoaded] = useState(true);

  //manually add logging for order summary page.
  useEffect(() => {
    const logInfo = () => {
      if (state.oneTimeString || JSON.parse(localStorage.getItem("oneTimeString"))) {
        api.report.logInfo({
          form: window.location.hash.slice(2, window.location.hash.length),
          oneTimeCode: state.oneTimeString || JSON.parse(localStorage.getItem("oneTimeString")),
        });
      }
    };
    logInfo();
    return () => window.location.hash !== "#/delivery" && logInfo();
  }, []);

  const placeOrder = () => {
    setPageLoaded(false);
    const tanksAreSelected = (itemsToOrder || regularItemsOrder).find((item) => item.isTank && item.checked);
    refreshToken(() =>
      api.order
        .post({
          oneTimeCode: state.oneTimeString || JSON.parse(localStorage.getItem("oneTimeString")),
          itemsList: (state.itemsToOrder.length ? state.itemsToOrder : JSON.parse(localStorage.getItem("itemsToOrder")))
            .filter((item) => item.checked)
            .map((item) => ({
              itemCode: item.itemCode,
              quantity: item.quantity,
              quantityFull: item.quantityFull,
              quantityEmpty: item.quantityEmpty,
              vendorItemCode: item.vendorItemCode,
              itemType: item.itemType
            })),
          deliveryDate:
            tanksAreSelected && selectedDeliveryDate
              ? selectedDeliveryDate && selectedDeliveryDate.replaceAll("/", "-") + "T00:00:00+00:00"
              : dayjs.utc().format("YYYY-MM-DDT00:00:00+00:00"),
          shippingAddress: {
            street: street,
            apartment: apartment,
            city: city,
            state: userState,
            zipCode: zipCode,
          },
        })
        .then((res) => {
          if (!res) return;
          if (!res.success) {
            dispatch({
              type: "SET_ERRORMESSAGE",
              data: res.data.Detail,
            });
            navigate("/message");
            return;
          }
          setPageLoaded(true);
          dispatch({
            type: "SET_ORDER_NUMBER",
            data: res.data.orderNumber,
          });
          navigate("/delivery");
        })
    );
  };
  return pageLoaded ? (
    <PageTemplate title={"Order Summary"}>
      <div style={{ padding: "0 20px" }}>
        <>
          <div>
            <h1>Selected Supply:</h1>
            <p>
              {(itemsToOrder || regularItemsOrder)
                .filter((item) => !item.isTank)
                .map((item, index, array) => {
                  return item.checked && (index + 1 !== array.length ? ` ${item.title},` : ` ${item.title}`);
                })}
            </p>
          </div>
          {itemsToOrder.some((item) => item.isTank) && <h1>Cylinders:</h1>}
          <div style={{ display: "flex", justifyContent: "flex-start", flexWrap: "wrap" }}>
            {itemsToOrder &&
              itemsToOrder
                .filter((item) => item.isTank)
                .map((item, index) => {
                  return (
                    <div style={{ maxWidth: "150px", margin: "20px" }}>
                      <div
                        key={item.itemCode}
                        style={{
                          // flex: 1,
                          borderRadius: "20px",
                          padding: "0 15px",
                          // margin: "10px",
                          backgroundColor: "white",
                          boxSizing: "border-box",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {/* <img
                          className="cylinder"
                          style={{ maxHeight: "200px", objectFit: "contain" }}
                          src={require(`../../../public/cylinders/${item.itemCode}.png`)}
                        /> */}
                        <div>{item.sizeDescription}</div>
                      </div>
                      <p>Quantity: {item.quantityEmpty || 0}</p>
                      {/* <p>Full: {item.quantityFull || 0}</p> */}
                    </div>
                  );
                })}
          </div>
          <div>
            <div style={{ marginTop: "10px", fontWeight: "bold", color: "rgb(1, 34, 65", fontSize: "24px" }}>
              To be delivered to:
              <p style={{ fontSize: "18px" }}>
                {street} {apartment}
              </p>
              <p style={{ fontSize: "18px" }}>
                {city}, {userState}, {zipCode}
              </p>
            </div>
            {selectedDeliveryDate && <p>The order will be delivered on {dayjs(selectedDeliveryDate).format("MM-DD-YYYY")}</p>}
          </div>
        </>

        <div style={{ marginTop: "30px" }}>
          <Button name="Place Order" onClick={placeOrder} style={{ background: "rgb(1, 34, 65)", color: "white" }} />
        </div>
        <Button
          name="Edit Order"
          onClick={() => {
            navigate("/item-select");
          }}
          style={{ background: "white", color: "rgb(1, 34, 65)", flex: "1" }}
        />
        <Button
          name="Back"
          onClick={() => {
            navigate(-1);
          }}
          style={{ background: "white", color: "rgb(1, 34, 65)", flex: "1" }}
        />
      </div>
    </PageTemplate>
  ) : (
    <LoadingSpinner />
  );
  return;
};
