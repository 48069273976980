import PageTemplate from "../../components/PageTemplate";
import Container from "../../components/container/Container";
import { useNavigate } from "react-router-dom";
import Button from "../../components/button/Button";
const SomethingWentWrong = () => {
  const navigate = useNavigate();
  localStorage.clear();
  return (
    <PageTemplate>
      <Container style={{ justifyContent: "center" }}>
        <p style={{ textAlign: "center" }}>Something Went wrong. Please try again.</p>
      </Container>
      <div style={{ marginTop: "40px" }}>
        {/* <Button name={"Back"} onClick={() => navigate(-1)} style={{ background: "white", color: "rgb(1, 34, 65)" }} /> */}
      </div>
    </PageTemplate>
  );
};
export default SomethingWentWrong;
