import { useContext, useEffect, useRef, useState } from "react";
import Input from "../../components/Input";
import Button from "../../components/button/Button";
import { api } from "../../services";
import { useNavigate } from "react-router-dom";
import { ProcessFlowContext } from "../../context/context";
import Container from "../../components/container/Container";
import PageTemplate from "../../components/PageTemplate";
import { LoadingSpinner } from "../../components/loadingSpinner/LoadingSpinner";

export const PhoneEntry = () => {
  const [preNumber, setPreNumber] = useState("+1");
  const [countryCode, setCountryCode] = useState("");
  const [restOfPhoneNumber, setRestOfPhoneNumber] = useState('');
  const [phoneNumber, setPhoneNumber] = useState(``);
  const { state, dispatch } = useContext(ProcessFlowContext);
  const countryCodeRef = useRef(null);
  const restPhoneRef = useRef(null);
  const [pageLoaded, setPageLoaded] = useState(true);
  const inputStyle = {
    border: " 0px",
    width: "100%",
    fontSize: "large",
    textAlign: "center",
    background: "white",
  };
  const approveSubmit = () => {
    const phoneRegex = new RegExp(/^\+1\d{10}$/);

    if (phoneRegex.test(`+1${countryCode}${restOfPhoneNumber}`)) return true;

    return false;
  };
  const navigate = useNavigate();
  const checkPhoneNumber = () => {
    setPageLoaded(false);
    api.phone.sendSMSQR(`+1${countryCode}${restOfPhoneNumber}`).then((res) => {
      if (!res) return;
      if (!res.success) {
        dispatch({
          type: "SET_ERRORMESSAGE",
          data: res.data.Detail,
        });
        navigate("/message");
        return;
      }
      if (res) {
        dispatch({
          type: "SET_ERRORMESSAGE",
          data: res.data.message,
        });
        navigate("/message");
        return;
      } else {
        dispatch({
          type: "SET_ERRORMESSAGE",
          data: res.data.Detail,
        });
        navigate("/message");
      }
    }).finally(() => {
      setPageLoaded(true)
    });
  };
  const handleCountryCode = (value) => {
    const regex = new RegExp(/^\d*$/);
    if (!regex.test(value)) return;
    if (value.length === 3) {
      restPhoneRef.current.focus();
    }
    if (value.length > 3) {
      return;
    }
    setCountryCode(value);
  };
  const handleRestOfPhoneNumber = (event) => {
    const value = event.target.value;
    const key = event.key;
    const regex = new RegExp(/^\d*$/);
    if (!regex.test(value)) return;
    if (value.length > 7) return;
    setRestOfPhoneNumber(value);
  };
  useEffect(() => {
    countryCodeRef.current.focus();
  }, []);

  return !pageLoaded ? (
    <LoadingSpinner />
  ) : (
    <PageTemplate title="Welcome!">
      <Container style={{ margin: 0, flexDirection: "column", height: "100px", position: "relative" }}>
        <>
          <Input placeholder="Please enter your phone number" style={inputStyle} disabled value={""} />
          <div style={{ display: "flex", justifyContent: "center", flexWrap: "nowrap", fontSize: "14px !important" }}>
            <p className="zipCode" style={{ paddingTop: "20px", width: "25px", letterSpacing: "2px" }}>
              +1
            </p>
            <p className="zipCode" style={{ paddingTop: "20px", width: "10px" }}>
              (
            </p>
            <input
              ref={countryCodeRef}
              className="zipCode"
              placeholder="###"
              style={{ ...inputStyle, marginTop: "20px", width: "72px", textAlign: "center", letterSpacing: "0.8em" }}
              onChange={(event) => {
                handleCountryCode(event.target.value);
              }}
              value={countryCode || ""}
              type="tel"
            />
            <p className="zipCode" style={{ paddingTop: "20px", width: "10px", position: "relative", left: "-10px" }}>
              )
            </p>
            <input
              ref={restPhoneRef}
              className="zipCode"
              style={{ ...inputStyle, marginTop: "20px", width: "192px", textAlign: "left", letterSpacing: "0.8em" }}
              onChange={(event) => {
                handleRestOfPhoneNumber(event);
              }}
              onKeyDown={({ key }) => {
                if ( restOfPhoneNumber.length === 0 && (key === "Backspace" || key === "Delete")) countryCodeRef.current.focus();
              }}
              value={restOfPhoneNumber || ""}
              placeholder="#######"
              type="tel"
            />
          </div>
        </>
      </Container>
      <Button
        name="Submit"
        onClick={() => {
          if (approveSubmit()) {
            checkPhoneNumber();
          }
        }}
        style={{ marginTop: "40px", background: "rgb(1, 34, 65)", color: "white", opacity: `${approveSubmit() ? "1" : "0.6"}` }}
      />

      <Button onClick={checkPhoneNumber}>Check</Button>
    </PageTemplate>
  );
};
