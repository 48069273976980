import { createAddressApi } from './address/address';
import { createDeliveryDateApi } from './delivery-date/deliveryDate';
import { fetchApi } from './fetch-api';
import { createValidateApi } from './home/login';
import { createOrderApi } from './order/order';
import { createPhoneApi } from './phone/phone';
import { createMessageAPI } from './message/message';
import { message } from '../hooks/ErrorMessage';
import { createReportApi } from './report/report';

function createApi(api) {
    return {
        home: createValidateApi(api),
        phone: createPhoneApi(api),
        address: createAddressApi(api),
        deliveryDate: createDeliveryDateApi(api),
        order: createOrderApi(api),
        message: createMessageAPI(api),
        report: createReportApi(api)
    };
}

export const api = createApi(fetchApi);