import PageTemplate from "../../components/PageTemplate";
import Container from "../../components/container/Container";
import React, { useEffect } from "react";
import { ProcessFlowContext } from "../../context/context";
import dayjs from "dayjs";
import Button from "../../components/button/Button";
const DeliveryPage = () => {
  const { state, dispatch } = React.useContext(ProcessFlowContext);
  useEffect(() => {
    dispatch({
      type: "SET_ONE_TIME_STRING",
      data: null,
    });
    localStorage.removeItem("date");
    localStorage.removeItem("zipCode");
    localStorage.removeItem("street");
    localStorage.removeItem("city");
    localStorage.removeItem("state");
    localStorage.removeItem("tanksToOffer");
    localStorage.removeItem("oneTimeString");
    localStorage.removeItem("itemsList");
    // localStorage.removeItem("selectedDeliveryDate");
    

    dispatch({
      type: "SET_ORDER_COMPLETE",
      data: true,
    });
  }, []);
  return (
    <PageTemplate>
      <Container style={{ justifyContent: "center", flexDirection: "column", textAlign: "center", color: "rgb(3 57 106)", fontSize: "20px" }}>
        {(state.selectedDeliveryDate  || JSON.parse(localStorage.getItem('selectedDeliveryDate'))) && (
          <>
            <div style={{ margin: "10px" }}>Your order will be delivered on:</div>
            <div style={{ margin: "10px", fontWeight: "bold" }}>{dayjs(state.selectedDeliveryDate || JSON.parse(localStorage.getItem('selectedDeliveryDate'))).format("dddd, MM-DD-YYYY")}</div>
            <div style={{ margin: "10px" }}>A delivery confirmation will be texted to you the day of delivery if you have signed up for delivery notifications.</div>
          </>
        )}
        <div style={{ margin: "10px" }}>Thank you for your order!</div>
        <div style={{ margin: "10px", fontWeight: "bold" }}> You may close the application now</div>
      </Container>
      {/* <Button name="Close" onClick={() => {}} style={{ background: "rgb(1, 34, 65)", color: "white", marginTop: "40px" }} /> */}
    </PageTemplate>
  );
};
export default DeliveryPage;
