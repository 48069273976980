import "./HomePage.css";
import Container from "../../components/container/Container";
import Input from "../../components/Input";
import { useNavigate, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { ProcessFlowContext } from "../../context/context";
import Button from "../../components/button/Button";
import PageTemplate from "../../components/PageTemplate";
import ExitModal from "../../components/modal/ExitModal/ExitModal";
import { api } from "../../services";
import dayjs from "dayjs";
import { LoadingSpinner } from "../../components/loadingSpinner/LoadingSpinner";
import DatePicker from "react-mobile-datepicker";
import DateSelector from "../../components/DateSelector/IosDatepicker";
import { refreshToken } from "../../hooks/TokenIsExpired";

const inputStyle = {
  border: " 0px",
  width: "100%",
  fontSize: "large",
  textAlign: "center",
  background: "white",
};
const HomePage = () => {
  const handleChange = (time) => {
    dispatch({
      type: "SET_DATE",
      data: dayjs(time).format("YYYY-MM-DD"),
    });
    localStorage.setItem("date", JSON.stringify(dayjs(time).format("YYYY-MM-DD")));
  };

  const navigate = useNavigate();
  const { state, dispatch } = React.useContext(ProcessFlowContext);
  const [validateDate, setValidateDate] = useState(true);
  const [validateCode, setValidateCode] = useState(true);
  const [validateLenghtCode, setValidateLenghtCode] = useState(true);
  const [validateZeroCode, setValidateZeroCode] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [queryParameters] = useSearchParams();
  const [pageLoaded, setPageLoaded] = useState(false);
  const [showDatepicker, setShowDatepicker] = useState(false);
  const [time, setTime] = useState(new Date(state.date) || new Date(JSON.parse(localStorage.getItem("date"))) || new Date());
  const [isShown, setIsShown] = useState(true);
  useEffect(() => {
    dispatch({
      type: "SET_ORDER_COMPLETE",
      data: false,
    });
    dispatch({
      type: "RESET_ITEMS",
      data: null,
    });

    const code = queryParameters.get("user_code") || state.oneTimeString;
    dispatch({ type: "SET_ONE_TIME_STRING", data: code });
    if (JSON.parse(localStorage.getItem("oneTimeString")) !== code) {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
    }
    api.home
      .checkOneTimeLink(code)
      .then((res) => {
        if (!res.data.result) {
          dispatch({
            type: "SET_ERRORMESSAGE",
            data: res.data.Detail,
          });
          navigate("/message");
          return;
        }
        localStorage.setItem("dbaName", res.data.dba);
        res.data.token && res.data.token.length && localStorage.setItem("token", res.data.token);
        res.data.refreshKey && res.data.refreshKey.length && localStorage.setItem("refreshKey", res.data.refreshKey);
        localStorage.setItem("oneTimeString", JSON.stringify(code));
        dispatch({
          type: "SET_DBA_NAME",
          data: res.data.dba,
        });
        setPageLoaded(true);
      })
      .catch((error) => {});

    if (localStorage.getItem("validate")) {
      dispatch({
        type: "SET_VALIDATEINFO",
        data: JSON.parse(localStorage.getItem("validate")),
      });
    }

    dispatch({
      type: "RESET_ITEMS",
      data: null,
    });
  }, []);
  const showModalHandler = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  const getItemSize = (itemCode) => {

    if (itemCode == "1134") return 1;
    if (itemCode == "1113") return 2;
    if (itemCode == "1137") return 3;
    if (itemCode == "1123") return 4;
    if (itemCode == "1132") return 5;
    if (itemCode == "1100") return 6;
    if (itemCode == "1101") return 7;
    if (itemCode == "1102") return 8;
    if (itemCode == "1117") return 9;

    return 0;
  };
  const getTankSuffix = (itemCode) => {
    if(!itemCode) return ''
    if(itemCode === '1137') return "ML6 "
    if(itemCode === '1123') return "B "
    if(itemCode === '1100') return "C "
    if(itemCode === '1101') return "D "
    if(itemCode === '1102') return "E "
    if(itemCode === '1117') return "M60 "
    return ''
   }
  function annotateTankSizes(tanks) {
    console.log(tanks)
    if (tanks.length === 1) {
      tanks[0].sizeDescription = `Replacement ${getTankSuffix(tanks[0].itemCode)} Tank`;
    } else if (tanks.length > 1) {
      // Sort the tanks by size, with the largest first
      tanks.sort((a, b) => b.size - a.size);

      tanks[0].sizeDescription = `Larger ${getTankSuffix(tanks[0].itemCode)}Tank`;
      tanks[1].sizeDescription = `Smaller ${getTankSuffix(tanks[1].itemCode)}Tank`;
    }
    return tanks;
  }
  const handleSubmit = () => {
    if (!state.date) {
      setValidateDate(false);
      return;
    } else setValidateDate(true);
    if (!state.zipCode) {
      setValidateCode(false);
      return;
    }
    if (state.zipCode.length !== 5) {
      setValidateLenghtCode(false);
      return;
    } else setValidateLenghtCode(true);
    if (state.zipCode === "00000") {
      setValidateZeroCode(false);
      return;
    } else setValidateZeroCode(true);

    const data = {
      dateOfBirth: `${dayjs(state.date).format("YYYY-MM-DD")}T00:00:00.000Z`,
      zipcode: state.zipCode,
      oneTimeCode: state.oneTimeString,
    };
    setPageLoaded(false);
    refreshToken(() =>
      api.home
        .validateDobZip(data)
        .then((res) => {
          if (!res) {
            return;
          }
          if (!res.success) {
            dispatch({
              type: "SET_ERRORMESSAGE",
              data: res.data.Detail,
            });
            if (res.data.Title === "NotAllowed") {
              localStorage.clear();
              navigate("/message", {
                state: {
                  buttonProps: [{ name: "Back", nextPage: -1, styles: { background: "white", color: "rgb(1, 34, 65)" } }],
                },
              });
              return;
            }
            refreshToken(() =>
              api.home.validateDobZipAllowed(state.oneTimeString).then((validateAllowed) => {
                if (!validateAllowed.data.allowed) {
                  api.home.getResendSmsInfo().then((smsInfo) => {
                    navigate("/message", {
                      state: {
                        buttonProps: [
                          {
                            name: "Re-Send Code",
                            nextPage: `sms://${smsInfo.data.shortCode}?&body=${smsInfo.data.smsContent}`,
                            external: true,
                            styles: { background: "white", color: "rgb(1, 34, 65)" },
                          },
                          { name: "Re-Enter Phone Number", nextPage: "/phone-entry", styles: { background: "white", color: "rgb(1, 34, 65)" } },
                        ],
                      },
                    });
                  });
                  localStorage.clear();
                } else {
                  navigate("/message", {
                    state: {
                      buttonProps: [{ name: "Back", nextPage: -1, styles: { background: "white", color: "rgb(1, 34, 65)" } }],
                    },
                  });
                }
              })
            );

            return;
          } else {
            setPageLoaded(true);
            dispatch({
              type: "SET_VALIDATEINFO",
              data: true,
            });
            dispatch({
              type: "SET_USER",
              data: res.data,
            });
            dispatch({
              type: "SET_STREET",
              data: res.data.street,
            });
            dispatch({
              type: "SET_APARTMENT",
              data: res.data.apartment,
            });
            dispatch({
              type: "SET_CITY",
              data: res.data.city,
            });
            dispatch({
              type: "SET_USERSTATE",
              data: res.data.state,
            });
            dispatch({
              type: "SET_TANKS_TO_OFFER",
              data: annotateTankSizes(
                res.data.itemsToOffer
                  .map((result) => ({
                    itemCode: result.itemCode,
                    size: getItemSize(result.itemCode),
                    vendorItemCode: result.vendorItemCode,
                    itemType: result.itemType,
                  }))
                  .sort((a, b) => b.size - a.size)
              ),
            });
            dispatch({
              type: "SET_CANNULAS_ID",
              data:
                res.data.literFlow <= 6 ? { itemCode: "2132-011", vendorItemCode: "SLT16SOFT750H" } : { itemCode: "2015-003", vendorItemCode: "HCS4514HFGH" },
            });

            localStorage.setItem("validate", JSON.stringify(true));
            localStorage.setItem("date", JSON.stringify(state.date));
            localStorage.setItem("zipCode", JSON.stringify(state.zipCode));
            localStorage.setItem("street", JSON.stringify(res.data.street));
            localStorage.setItem("apartment", JSON.stringify(res.data.apartment));
            localStorage.setItem("city", JSON.stringify(res.data.city));
            localStorage.setItem("state", JSON.stringify(res.data.state));
            localStorage.setItem(
              "tanksToOffer",
              JSON.stringify(
                annotateTankSizes(
                  res.data.itemsToOffer
                    .map((result) => ({
                      itemCode: result.itemCode,
                      size: getItemSize(result.itemCode),
                      vendorItemCode: result.vendorItemCode,
                      itemType: result.itemType,
                    }))
                    .sort((a, b) => b.size - a.size)
                    .map((item) => ({}))
                )
              )
            );
            navigate("/item-select");
          }
        })
        .catch((err) => {})
    );
  };

  const onChangeInputZipCode = (e) => {
    const { value } = e.target;
    const numberRegex = new RegExp("^[0-9]*$");
    if (value.length > 5 || !numberRegex.test(value)) return;
    dispatch({
      type: "SET_ZIPCODE",
      data: value,
    });
    setValidateCode(true);
  };

  const approveSubmit = () => {
    if (!state.date) return false;
    if (!state.zipCode) return false;
    if (state.zipCode.length !== 5) return false;
    if (state.zipCode === "00000") return false;
    return true;
  };
  return !pageLoaded ? (
    <LoadingSpinner />
  ) : (
    <PageTemplate title={`${state.dbaName} Oxygen Ordering`}>
      <DateSelector isShown={isShown} setIsShown={setIsShown} setValidateDate={setValidateDate} />

      <form className="form-container" onSubmit={handleSubmit}>
        <Container style={{ flexDirection: "column", height: "100px", margin: 0, justifyContent: "center" }} handleDate={() => setIsShown(true)}>
          <>
            <Input placeholder="Please enter your date of birth" style={inputStyle} disabled />
            <input
              placeholder={state.date ? dayjs(state.date).format("MM/DD/YYYY") : "MM/DD/YYYY"}
              style={{ ...inputStyle, marginTop: "20px" }}
              disabled
              value={state.date ? dayjs(state.date).format("MM/DD/YYYY") : ""}
            />

            <p style={{ display: validateDate ? "none" : "block" }} className="errorMessage">
              Date of birth is required to continue
            </p>
          </>
        </Container>
        <Container style={{ margin: 0, flexDirection: "column", height: "100px", position: "relative" }}>
          <>
            <Input placeholder="Please enter your zip code" style={inputStyle} disabled value={""} />
            <input
              className="zipCode"
              placeholder="#####"
              style={{ ...inputStyle, marginTop: "20px" }}
              onChange={onChangeInputZipCode}
              value={state.zipCode || ""}
              type="tel"
              onFocus={() => setIsShown(false)}
            />
            <p style={{ display: validateCode ? "none" : "block" }} className="errorMessage">
              Zip code is required to continue
            </p>
            <p style={{ display: validateLenghtCode ? "none" : "block" }} className="errorMessage">
              Zip code must be in the form of 5 digits
            </p>
            <p style={{ display: validateZeroCode ? "none" : "block" }} className="errorMessage">
              Not valid zip code
            </p>
          </>
        </Container>
      </form>
      <div id="buttons">
        <Button name="Submit" onClick={handleSubmit} style={{ background: "rgb(1, 34, 65)", color: "white", opacity: `${approveSubmit() ? "1" : "0.6"}` }} />
        <Button
          name="Cancel"
          onClick={() => {
            showModalHandler();
          }}
          style={{ background: "white", color: "rgb(1, 34, 65)" }}
        />
      </div>
      <ExitModal show={showModal} handleClose={closeModal}>
        <p style={{ color: "rgb(1  34 65)" }}>Are you sure you want to cancel?</p>
      </ExitModal>
    </PageTemplate>
  );
};
export default HomePage;
