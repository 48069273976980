import PageTemplate from "../../components/PageTemplate";
import Button from "../../components/button/Button";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { ProcessFlowContext } from "../../context/context";
import Item from "../../components/item/Item";
import Modal from "../../components/modal/Modal";
import { AddressWidget } from "../../components/addressWidget/AddressWidget";
import { api } from "../../services";
import dayjs from "dayjs";
import ExitModal from "../../components/modal/ExitModal/ExitModal";
import { LoadingSpinner } from "../../components/loadingSpinner/LoadingSpinner";
import { refreshToken } from "../../hooks/TokenIsExpired";

const DeliveryDateSelection = () => {
  const navigate = useNavigate();
  const { state, dispatch } = React.useContext(ProcessFlowContext);
  const [showModal, setShowModal] = useState(false);
  const [deliveryDates, setDeliveryDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [showExitModal, setShowExitModal] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  useEffect(() => {
    refreshToken(() =>
      api.deliveryDate
        .getDeliveryTimes(
          {
            street: state.street,
            apartment: state.apartment,
            city: state.city,
            state: state.userState,
            zipCode: state.zipCode,
          },
          state.oneTimeString || localStorage.getItem("oneTimeString")
        )
        .then((res) => {
          if (!res) {
            return;
          }
          if (!res.success) {
            dispatch({
              type: "SET_ERRORMESSAGE",
              data: res.data.Detail,
            });
            navigate("/message");
            return;
          } else {
            setPageLoaded(true);
            const deliveryDatesMap = res.data.rescheduleDates.map((date) => ({
              title: date.slice(0, 10).replaceAll("-", "/"),
              checked: false,
            }));
            deliveryDatesMap.push({ title: "None of the above", checked: false });
            dispatch({
              type: "SET_DELIVERY_DATES",
              data: deliveryDatesMap,
            });
            setDeliveryDates(deliveryDatesMap);
            setSelectedDate(state.selectedDeliveryDate || localStorage.getItem("selectedDeliveryDate") || deliveryDatesMap[0].title);
            dispatch({
              type: "SET_SELECTED_DELIVERY_DATE",
              data: deliveryDatesMap[0].title,
            });
            localStorage.setItem(
              "selectedDeliveryDate",
              state.selectedDeliveryDate || JSON.parse(localStorage.getItem("selectedDeliveryDate")) || deliveryDatesMap[0].title
            );
          }
        })
    );
  }, []);
  const handleSubmit = () => {
    if (selectedDate === "None of the above") {
      const oneTimeCode = JSON.parse(localStorage.getItem("oneTimeString"));
      const refreshKey = localStorage.getItem("refreshKey");
      console.log(oneTimeCode, refreshKey)

      api.message.getMessage("NODELIVERYDATESELECTED", oneTimeCode, refreshKey).then((res) => {
        if (!res) {
          return;
        }
        if (!res.success) {
          dispatch({
            type: "SET_ERRORMESSAGE",
            data: res.data.Detail,
          });
          navigate("/message", {
            state: {
              buttonProps: [{ name: "Back", nextPage: -1, styles: { background: "white", color: "rgb(1, 34, 65)" } }],
            },
          });
          return;
        } else {
          dispatch({
            type: "SET_ERRORMESSAGE",
            data: res.data.message,
          });

          dispatch({
            type: "SET_VALIDATEINFO",
            data: false,
          });

          dispatch({
            type: "SET_ONE_TIME_STRING",
            data: null,
          });

          localStorage.removeItem("date");
          localStorage.removeItem("zipCode");
          localStorage.removeItem("street");
          localStorage.removeItem("city");
          localStorage.removeItem("state");
          localStorage.removeItem("tanksToOffer");
          localStorage.removeItem("oneTimeString");
          localStorage.removeItem("token");
          localStorage.removeItem("refreshKey");
          localStorage.clear();

          navigate("/message");
          return;
        }
      }).finally(() => {
        localStorage.clear();

      })
    }
    if (!selectedDate) {
      showModalHandler();
      return;
    }
    setPageLoaded(false);
    navigate("/order-summary");
  };
  const showModalHandler = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  const handleItem = (item) => {
    dispatch({
      type: "SET_SELECTED_DELIVERY_DATE",
      data: item.title === "None of the above" ? null : item.title,
    });
    setSelectedDate(item.title);
    localStorage.setItem("selectedDeliveryDate", JSON.stringify(item.title === "None of the above" ? null : item.title));
  };
  return !pageLoaded ? (
    <LoadingSpinner />
  ) : (
    <>
      {/* <AddressWidget /> */}
      <PageTemplate title="Select the date you would like to receive your order.">
        {deliveryDates.map((item) => {
          return (
            <div
              key={item.title}
              className="form-elements"
              onClick={() => {
                handleItem(item);
              }}
              style={{ position: "relative", marginBottom: "30px" }}
            >
              <div className="form-element flex" style={{ justifyContent: "space-between" }}>
                <div>{dayjs(item.title).isValid() ? dayjs(item.title).format("MM-DD-YYYY") : item.title}</div>
                <div
                  style={{
                    background: item.title === selectedDate ? "  rgb(1, 34, 65, 0.8)" : "white",
                    border: "1px solid grey",
                    width: "20px",
                    height: "20px",
                    borderRadius: "12px",
                  }}
                />
              </div>
            </div>
          );
        })}
        <div id="buttons">
          <Button name="Submit" onClick={handleSubmit} style={{ background: "rgb(1, 34, 65)", color: "white" }} />
          <Button
            name="Back"
            onClick={() => {
              navigate(-1);
            }}
            style={{ background: "white", color: "rgb(1, 34, 65)" }}
          />
          <Button
            name="Cancel"
            onClick={() => {
              setShowExitModal(true);
            }}
            style={{ background: "white", color: "rgb(1, 34, 65)" }}
          />
        </div>
        <Modal show={showModal} handleClose={closeModal} closeButtonTitle="Okay">
          <p>Please select at least one item</p>
        </Modal>

        <ExitModal show={showExitModal} handleClose={setShowExitModal}>
          <p style={{ color: "rgb(1  34 65)" }}>Are you sure you want to cancel?</p>
        </ExitModal>
      </PageTemplate>
    </>
  );
};
export default DeliveryDateSelection;
