export const REACT_APP_API_URL = process.env.REACT_APP_API_URL
export const REACT_APP_API_VERSION = "/api/v1"
// Async function to fetch config and return a specific variable
export const getApiUrlConfigVariable = async () => {
    try {
        const response = await fetch('/config/config.json');
        const config = await response.json();
        return config["API_URL"];
    } catch (error) {
        console.error('Error fetching configuration:', error);
        return 'NOT FOUND'; // or a default value, if applicable
    }
};