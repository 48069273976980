import "./AdressPage.css";
import PageTemplate from "../../components/PageTemplate";
import Container from "../../components/container/Container";
import Button from "../../components/button/Button";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { ProcessFlowContext } from "../../context/context";
import { api } from "../../services";
import ExitModal from "../../components/modal/ExitModal/ExitModal";
import { LoadingSpinner } from "../../components/loadingSpinner/LoadingSpinner";
const AdressPage = () => {
  const navigate = useNavigate();
  const { state, dispatch } = React.useContext(ProcessFlowContext);
  const [showExitModal, setShowExitModal] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("validate")) {
      dispatch({
        type: "SET_VALIDATEINFO",
        data: JSON.parse(localStorage.getItem("validate")),
      });
    }
    if (localStorage.getItem("street")) {
      dispatch({
        type: "SET_STREET",
        data: JSON.parse(localStorage.getItem("street")),
      });
    }
    if (localStorage.getItem("city")) {
      dispatch({
        type: "SET_CITY",
        data: JSON.parse(localStorage.getItem("city")),
      });
    }
    if (localStorage.getItem("state")) {
      dispatch({
        type: "SET_USERSTATE",
        data: JSON.parse(localStorage.getItem("state")),
      });
    }
    if (localStorage.getItem("zipCode")) {
      dispatch({
        type: "SET_ZIPCODE",
        data: JSON.parse(localStorage.getItem("zipCode")),
      });
    }
  }, []);
  const handleConfirm = () => {
    const tanksAreSelected = [...state.itemsToOrder, ...JSON.parse(localStorage.getItem("itemsToOrder"))].some((item) => item.isTank);
    if (!!tanksAreSelected) {
      navigate("/delivery-date");
      return;
    }
    console.log();
    navigate("/order-summary");
  };

  return !pageLoaded ? (
    <LoadingSpinner />
  ) : (
    <PageTemplate title="Is this the address you want items delivered to ?">
      <Container style={{ justifyContent: "center" }}>
        <div id="address">
          <p>
            {state.street} {state.apartment}
          </p>
          <p>{state.city}</p>
          <p>{state.userState}</p>
          <p>{state.zipCode}</p>
        </div>
      </Container>
      <div id="buttons">
        <Button
          name="Confirm"
          onClick={() => {
            handleConfirm();
          }}
          style={{ background: "rgb(1, 34, 65)", color: "white" }}
        />
        <Button
          name="Change/Update"
          onClick={() => {
            dispatch({
              type: "SET_ADDRESS_WIDGET_LAST_VISITED_PAGE",
              data: "/address",
            });
            navigate("/address-update");
          }}
          style={{ background: "rgb(1, 34, 65)", color: "white" }}
        />
        <Button
          name="Back"
          onClick={() => {
            navigate(-1);
          }}
          style={{ background: "white", color: "rgb(1, 34, 65)" }}
        />
        <Button
          name="Cancel"
          onClick={() => {
            setShowExitModal(true);
          }}
          style={{ background: "white", color: "rgb(1, 34, 65)" }}
        />
      </div>

      <ExitModal show={showExitModal} handleClose={setShowExitModal}>
        <p style={{ color: "rgb(1  34 65)" }}>Are you sure you want to cancel?</p>
      </ExitModal>
    </PageTemplate>
  );
};
export default AdressPage;
