import React, { useEffect, useState } from 'react';
import { ProcessFlowContext } from '../../context/context';
import './Item.css';
const Item = ({allItems, setAllItems, item, style}) => {
  const [ checkedItem, isChecked ] = useState(item.checked)
  const { state } = React.useContext(ProcessFlowContext);

  const handleItem = () => {
    setAllItems(prev=>{
      const newItems = [...prev]
      // const p = newItems.find(i=>i.id === item.id).checked = (!item.checked)
      return newItems
    })
    const i = allItems.find(i => i.id === item.id).checked = (!item.checked)
    isChecked(prev => !prev)
  }
  useEffect(() => {

  }, [])
  return (   
    <div className='form-elements' 
      onClick={() => {handleItem()}} 
        style={{ position: 'relative', marginBottom: '30px'}}>
      <div className='form-element flex' style={{...style, justifyContent: 'space-between'}}>
        <div>
          {item.title}
        </div>
        <div style={{background: checkedItem ? '  rgb(1, 34, 65, 0.8)' : 'white', border: '1px solid grey', width: '20px', height: '20px', borderRadius: '12px'}} />
      </div>
    </div>
  )
} 
export default Item;