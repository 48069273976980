import Header from "./Header";

const PageTemplate = ({title, children}) => {

  return (
    <div >
      <Header />
      <div className="wrapper">
        <div id="subtitle" style={{textAlign: 'center', margin: 0, marginTop: '17px'}}>
          <p style={{margin: 0, paddingInline: '23px'}}>{title}</p>
          <br/>
        </div>
        {children}
      </div>
    </div>
  )
} 
export default PageTemplate;