import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { ProcessFlowContext } from "../../context/context";
import Button from "../../components/button/Button";
import PageTemplate from "../../components/PageTemplate";
import Modal from "../../components/modal/Modal";
import ExitModal from "../../components/modal/ExitModal/ExitModal";

const AmountPickerPage = () => {
  const navigate = useNavigate();
  const { state, dispatch } = React.useContext(ProcessFlowContext);
  const [validEmptyCylinders, isValidEmptyCylinders] = useState(false);
  const [validFullCylinders, isValidFullCylinders] = useState(false);
  const [showExitModal, setShowExitModal] = useState(false);
  const [itemsToOrder, setItemsToOrder] = useState((state.itemsToOrder.length ? state.itemsToOrder : JSON.parse(localStorage.getItem("itemsToOrder"))) || []);
  const [selectedTanks, setSelectedTanks] = useState([]);

  useEffect(() => {
    if (!state.itemsToOrder.length) {
      setItemsToOrder(JSON.parse(localStorage.getItem("itemsToOrder")));
      dispatch({
        type: "SET_ITEMS_TO_ORDER",
        data: JSON.parse(localStorage.getItem("itemsToOrder")),
      });
    }
    if (state.itemsToOrder.length) {
      setSelectedTanks(state.itemsToOrder.filter((item) => item.isTank && item.checked).map((item) => item.itemCode));
    } else if (JSON.parse(localStorage.getItem("itemsToOrder")) && JSON.parse(localStorage.getItem("itemsToOrder")).length) {
      setSelectedTanks(
        JSON.parse(localStorage.getItem("itemsToOrder"))
          .filter((item) => item.isTank && item.checked)
          .map((item) => item.itemCode)
      );
    } else {
      setSelectedTanks([]);
    }
  }, []);

  const handleSubmit = () => {
    if (!selectedTanks.length) return;

    dispatch({
      type: "SET_ITEMS_TO_ORDER",
      data: itemsToOrder.map((item) => {
        return {
          ...item,
          ...(item.quantityEmpty === "" ? { quantityEmpty: 0 } : { quantityEmpty: item.quantityEmpty }),
          ...(item.quantityFull === "" ? { quantityFull: 0 } : { quantityFull: item.quantityFull }),
        };
      }),
    });
    localStorage.setItem("itemsToOrder", JSON.stringify(itemsToOrder));
    if (state.itemsToOrder.find((item) => {
      console.log(item)
      return !item.quantityFull && !item.quantityEmpty && item.checked && item.isTank
    })) {
      isValidEmptyCylinders(true);
      return;
    }
    dispatch({
      type: "SET_ITEMS_TO_ORDER",
      data: state.itemsToOrder.filter((item) => item.checked),
    });

    navigate("/address");
  };
  const closeModal = () => {
    isValidEmptyCylinders(false);
    isValidFullCylinders(false);
  };
  const tanksToOffer = state.tanksToOffer.length ? state.tanksToOffer : JSON.parse(localStorage.getItem("tanksToOffer"));

  useEffect(() => {
    console.log('tankstoofer', tanksToOffer)
    const currentItems = [...state.items];
    currentItems.shift();
    setItemsToOrder([
      ...currentItems,
      ...selectedTanks.map((tank) => ({
        id: 1,
        itemCode: tank,
        title: "Oxygen cylinders",
        checked: true,
        quantityFull: state.itemsToOrder.find((item) => item.itemCode === tank) ? state.itemsToOrder.find((item) => item.itemCode === tank).quantityFull : 0,
        quantityEmpty: state.itemsToOrder.find((item) => item.itemCode === tank) ? state.itemsToOrder.find((item) => item.itemCode === tank).quantityEmpty : 0,
        isTank: true,
        sizeDescription: tanksToOffer.find(tankToffer=>tankToffer.itemCode === tank).sizeDescription,
        vendorItemCode: tanksToOffer.find(tankToffer=>tankToffer.itemCode === tank).vendorItemCode,
        itemType: tanksToOffer.find(tankToffer=>tankToffer.itemCode === tank).itemType
      })),
    ]);
  }, [selectedTanks]);

  useEffect(() => {
    if (tanksToOffer.length === 1) {
      setSelectedTanks([tanksToOffer[0].itemCode]);
    }
  }, []);

  useEffect(() => {
    dispatch({
      type: "SET_ITEMS_TO_ORDER",
      data: itemsToOrder,
    });
  }, [itemsToOrder]);

  return (
    <>
      <PageTemplate title="Please update your cylinder count">
        <form className="cylinderCount" onSubmit={handleSubmit}>
          {tanksToOffer.map(({ itemCode: tank, size, sizeDescription }, index) => {
            return (
              <div key={tank} style={{ minWidth: "180px", maxWidth: "49%" }}>
                <div
                  key={tank}
                  onClick={() => {
                    if (selectedTanks.includes(tank)) {
                      setSelectedTanks((prev) => [...prev.filter((t) => t !== tank)]);
                      return;
                    } else {
                      setSelectedTanks((prev) => [...prev, tank]);
                    }
                  }}
                  style={{
                    ...(selectedTanks.includes(tank) ? { border: "3px solid rgb(1, 34, 65)" } : { border: "3px solid transparent" }),
                    backgroundColor: "white",
                    borderRadius: "20px",
                    padding: "5px",
                    margin: "10px",
                    boxSizing: "border-box",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <img className="cylinder" style={{ maxHeight: "200px", objectFit: "contain" }} src={require(`../../../public/cylinders/${tank}.png`)} /> */}
                  <div>
                    {sizeDescription}
                  </div>
                </div>
                <div>
                  <div style={{ padding: "10px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <label>Empty cylinders</label>
                    <input
                      style={{ width: "55px", height: "25px", textAlign: "center" }}
                      type="text"
                      placeholder="0"
                      max={20}
                      min={0}
                      value={itemsToOrder.find((item) => item.itemCode === tank) ? itemsToOrder.find((item) => item.itemCode === tank).quantityEmpty : ""}
                      disabled={!selectedTanks.includes(tank)}
                      onChange={(e) => {
                        if ((parseInt(e.target.value) < 21 && parseInt(e.target.value) > -1) || e.target.value === "") {
                          let numberValue = parseInt(e.target.value);
                          if (isNaN(numberValue)) numberValue = "";
                          setItemsToOrder((prev) => {
                            const newValue = [...prev];
                            newValue.find((item) => item.itemCode === tank).quantityEmpty = numberValue;
                            return newValue;
                          });
                          itemsToOrder.find((item) => item.itemCode === tank).quantityEmpty = numberValue;
                        }
                      }}
                      onFocus={(e)=>{
                        if(e.target.value === null || e.target.value.match(/^ *$/) !== null || e.target.value == 0){
                          e.target.value = ''
                        }
                      }}
                      onBlur={(e)=>{
                        if(e.target.value === null || e.target.value.match(/^ *$/) !== null){
                          e.target.value = 0
                        }
                      }}
                    />
                  </div>
                  <div style={{ padding: "10px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <label>Full cylinders</label>
                    <input
                      style={{ width: "55px", height: "25px", textAlign: "center" }}
                      type="text"
                      placeholder="0"
                      max={20}
                      min={0}
                      value={itemsToOrder.find((item) => item.itemCode === tank) ? itemsToOrder.find((item) => item.itemCode === tank).quantityFull : ""}
                      disabled={!selectedTanks.includes(tank)}
                      onChange={(e) => {
                        if ((parseInt(e.target.value) < 21 && parseInt(e.target.value) > -1) || e.target.value === "") {
                          let numberValue = parseInt(e.target.value);
                          if (isNaN(numberValue)) numberValue = "";
                          setItemsToOrder((prev) => {
                            const newValue = [...prev];
                            newValue.find((item) => item.itemCode === tank).quantityFull = numberValue;
                            return newValue;
                          });
                        }
                      }}
                      onFocus={(e)=>{
                        if(e.target.value === null || e.target.value.match(/^ *$/) !== null || e.target.value == 0){
                          e.target.value = ''
                        }
                      }}
                      onBlur={(e)=>{
                        if(e.target.value === null || e.target.value.match(/^ *$/) !== null){
                          e.target.value = 0
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </form>
        <div style={{ marginTop: " 20px" }}>
          <Button
            name="Submit"
            onClick={handleSubmit}
            style={{ background: "rgb(1, 34, 65)", color: "white", ...(!selectedTanks.length ? { opacity: 0.7 } : { opacity: 1 }) }}
          />
          <div id="updateButtons">
            <Button
              name="Back"
              onClick={() => {
                navigate(-1);
              }}
              style={{ background: "white", color: "rgb(1, 34, 65)", flex: "1" }}
            />
            <Button
              name="Cancel"
              onClick={() => {
                setShowExitModal(true);
              }}
              style={{ background: "white", color: "rgb(1, 34, 65)", flex: "1" }}
            />
          </div>
        </div>
        <Modal show={validEmptyCylinders} handleClose={closeModal} closeButtonTitle="Okay">
          <p>Both full and empty cylinders can't be 0.</p>
        </Modal>
        <Modal show={validFullCylinders} handleClose={closeModal} closeButtonTitle="Okay">
          <p>Please enter the number of full cylinders</p>
        </Modal>

        <ExitModal show={showExitModal} handleClose={setShowExitModal}>
          <p style={{ color: "rgb(1  34 65)" }}>Are you sure you want to cancel?</p>
        </ExitModal>
      </PageTemplate>
    </>
  );
};
export default AmountPickerPage;
