import React from "react";

const InitialState = {
  user: null,
  date: null,
  zipCode: "",
  validateInfo: false,
  street: "",
  apartment: "",
  city: "",
  userState: "",
  items: [
    {
      id: 1,
      itemCode: "0",
      title: "Oxygen cylinders",
      checked: false,
      quantity: 0,
    },
    {
      id: 2,
      itemCode: "0",
      title: "Cannulas",
      checked: false,
      quantity: 2,
      itemType: "Supply",
      vendorItemCode: "",
    },
    {
      id: 3,
      itemCode: "2132-001",
      title: "Tubing",
      checked: false,
      quantity: 1,
      itemType: "Supply",
      vendorItemCode: "HCS4507H",
    },
  ],
  errorMessage: "",
  emptyCylinders: null,
  fullCylinders: null,
  oneTimeString: false,
  addressWidgetLastVisitedPage: false,
  deliveryDates: [],
  selectedDeliveryDate: null,
  orderNumber: false,
  tanksToOffer: [],
  itemsToOrder: [],
  dbaName: "",
  orderComplete: false,
};

export const ProcessFlowContext = React.createContext();

const processFlowReducer = (state, action) => {
  switch (action.type) {
    case "SET_USER": {
      const date = action.data ? action.data : null;
      return { ...state, user: date };
    }
    case "SET_DATE": {
      const date = action.data ? action.data : null;
      return { ...state, date: date };
    }
    case "SET_ZIPCODE": {
      const date = action.data ? action.data : null;
      return { ...state, zipCode: date };
    }
    case "SET_STREET": {
      const date = action.data ? action.data : null;
      return { ...state, street: date };
    }
    case "SET_APARTMENT": {
      const date = action.data ? action.data : null;
      return { ...state, apartment: date };
    }
    case "SET_CITY": {
      const date = action.data ? action.data : null;
      return { ...state, city: date };
    }
    case "SET_USERSTATE": {
      const date = action.data ? action.data : null;
      return { ...state, userState: date };
    }
    case "SET_ITEMS_LIST": {
      return { ...state, items: action.data ?? null };
    }
    case "SET_ERRORMESSAGE": {
      const date = action.data ? action.data : null;
      return { ...state, errorMessage: date };
    }
    case "SET_EMPTYCYLINDERS": {
      const date = action.data ? action.data : null;
      return { ...state, emptyCylinders: date };
    }
    case "SET_FULLCYLINDERS": {
      const date = action.data ? action.data : null;
      return { ...state, fullCylinders: date };
    }
    case "SET_VALIDATEINFO": {
      const date = action.data ? action.data : null;
      return { ...state, validateInfo: date };
    }
    case "SET_ONE_TIME_STRING": {
      const value = action.data ? action.data : null;
      return { ...state, oneTimeString: value };
    }
    case "SET_ADDRESS_WIDGET_LAST_VISITED_PAGE": {
      const value = action.data ? action.data : null;
      return { ...state, addressWidgetLastVisitedPage: value };
    }
    case "SET_DELIVERY_DATES": {
      const value = action.data ? action.data : null;
      return { ...state, deliveryDates: value };
    }
    case "SET_SELECTED_DELIVERY_DATE": {
      const value = action.data ? action.data : null;
      return { ...state, selectedDeliveryDate: value };
    }
    case "SET_ORDER_NUMBER": {
      const value = action.data ? action.data : null;
      return { ...state, orderNumber: value };
    }
    case "SET_TANKS_TO_OFFER": {
      const value = action.data ? action.data : null;
      return { ...state, tanksToOffer: value };
    }
    case "SET_ITEMS_TO_ORDER": {
      const value = action.data ? action.data : null;
      return { ...state, itemsToOrder: value };
    }
    case "SET_DBA_NAME": {
      const value = action.data ? action.data : null;
      return { ...state, dbaName: value };
    }
    case "SET_CANNULAS_ID": {
      const itemsWithUpdatedCannulasID = state.items.map((item) => {
        if (item.title === "Cannulas") {
          return { ...item, itemCode: action.data.itemCode, vendorItemCode: action.data.vendorItemCode };
        }
        return item;
      });
      return { ...state, items: itemsWithUpdatedCannulasID };
    }

    case "RESET_ITEMS": {
      return {
        ...state,
        items:  [
          {
            id: 1,
            itemCode: "0",
            title: "Oxygen cylinders",
            checked: false,
            quantity: 0,
          },
          {
            id: 2,
            itemCode: "0",
            title: "Cannulas",
            checked: false,
            quantity: 2,
            itemType: "Supply",
            vendorItemCode: "",
          },
          {
            id: 3,
            itemCode: "2132-001",
            title: "Tubing",
            checked: false,
            quantity: 1,
            itemType: "Supply",
            vendorItemCode: "HCS4507H",
          },
        ],
        itemsToOrder: [],
      };
    }
    case "SET_ORDER_COMPLETE": {
      localStorage.removeItem("validate");
      localStorage.removeItem("date");
      localStorage.removeItem("zipCode");
      localStorage.removeItem("street");
      localStorage.removeItem("apartment");
      localStorage.removeItem("city");
      localStorage.removeItem("state");
      localStorage.removeItem("tanksToOffer");
      localStorage.removeItem("itemsToOrder");
      localStorage.removeItem("itemsList");
      // localStorage.removeItem("dbaName");
      localStorage.removeItem("oneTimeString");
      const value = action.data ? action.data : null;
      return { ...InitialState, dba: state.dbaName, selectedDeliveryDate: state.selectedDeliveryDate, orderComplete: value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export const ProcessFlowProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(processFlowReducer, InitialState);
  const value = { state, dispatch };
  return <ProcessFlowContext.Provider value={value}>{children}</ProcessFlowContext.Provider>;
};
