import "./App.css";
import { Routes, Route, HashRouter, Navigate, useLocation } from "react-router-dom";
import HomePage from "./pages/homepage/HomePage";
import DatePickerPage from "./pages/datePicker/DatePicker";
import { ProcessFlowContext } from "./context/context";
import AdressPage from "./pages/addresspage/AddressPage";
import ChangeAddressPage from "./pages/addresspage/ChangeAddress";
import ErrorPage from "./pages/errorpage/ErrorPage";
import ItemSelectPage from "./pages/itemselect/ItemSelect";
import AmountPickerPage from "./pages/amountpicker/AmountPickerPage";
import DeliveryPage from "./pages/deliverypage/DeliveryPage";
import React, { useEffect, useState } from "react";
import NotFound from "./pages/notfoundpage/NotFound";
import { PhoneEntry } from "./pages/phoneEntry/PhoneEntry";
import DeliveryDateSelection from "./pages/deliverydateselection/DeliveryDateSelection";
import { CylinderChoice } from "./pages/cylinderchoice/CylinderChoice";
import { OrderSummary } from "./pages/orderSummary/OrderSummary";
import SomethingWentWrong from "./pages/errorpage/SomethingWentWrong";
import { api } from "./services";
import { ForeignBranches } from "./pages/addresspage/ForeignBranches";

function App() {
  const { state } = React.useContext(ProcessFlowContext);
  if (state.orderComplete) {
    window.location = "#/delivery";
  }


  useEffect(() => {
    let form = window.location.hash.slice(2, window.location.hash.length)
    if(window.location.hash.indexOf('?') !== -1){
      form = window.location.hash.slice(2, window.location.hash.length).split('?')[0]
    }
    if (state.oneTimeString || JSON.parse(localStorage.getItem("oneTimeString"))) {
      api.report.logInfo({
        form,
        oneTimeCode: state.oneTimeString || JSON.parse(localStorage.getItem("oneTimeString")),
      });
    }
  }, [window.location.hash, state.oneTimeString ]);
  console.log(process.env.REACT_APP_TEST_VAR)


  return (
    <div className="App">
      <HashRouter>
        <div id="main-content">
          <Routes>
            <Route path="/" element={<Navigate to={"/home"} replace />} />
            <Route path="/home" element={<HomePage pageTitle="Home" />} />
            <Route path="/date" element={<DatePickerPage pageTitle="Home" />} />
            <Route path="/phone-entry" element={<PhoneEntry />} />
            {(state.validate || localStorage.getItem("validate")) && (
              <>
                <Route path="/address" element={<AdressPage pageTitle="Adress" />} />
                <Route path="/address-update" element={<ChangeAddressPage pageTitle="UpdateAdress" />} />
                <Route path="/item-select" element={<ItemSelectPage pageTitle="ItemSelect" />} />
                <Route path="/cylinder-choice" element={<CylinderChoice pageTitle="ItemSelect" />} />
                <Route path="/amountpicker" element={<AmountPickerPage pageTitle="AmountPicker" />} />
                <Route path="/delivery-date" element={<DeliveryDateSelection pageTitle="AmountPicker" />} />
                <Route path="/order-summary" element={<OrderSummary />} />
                {/* <Route path="/emailToForeignBranches" element={<ForeignBranches />}/> */}
              </>
            )}
            <Route path="/delivery" element={<DeliveryPage pageTitle="Delivery" />} />
            <Route path="/message" element={<ErrorPage pageTitle="Error" />} />
            <Route path="/something-went-wrong" element={<SomethingWentWrong />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </HashRouter>
    </div>
  );
}

export default App;
