export const createOrderApi = (fetchApi) => {
  function post(data) {
    return fetchApi(`/Order`, "POST", data);
  }
  function cancel() {
    return fetchApi(`/CancelOrderMessage`, "GET");
  }

  return {
    post,
    cancel
  };
};
