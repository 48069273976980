import PageTemplate from "../../components/PageTemplate";
import Button from "../../components/button/Button";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { ProcessFlowContext } from "../../context/context";
import Item from "../../components/item/Item";
import Modal from "../../components/modal/Modal";
import { AddressWidget } from "../../components/addressWidget/AddressWidget";
import ExitModal from "../../components/modal/ExitModal/ExitModal";

const ItemSelectPage = () => {
  const navigate = useNavigate();
  const { state, dispatch } = React.useContext(ProcessFlowContext);
  const [showModal, setShowModal] = useState(false);
  const [showExitModal, setShowExitModal] = useState(false);
  const [itemsList, setItemsList] = useState(
    localStorage.getItem("itemsList") && JSON.parse(localStorage.getItem("itemsList")).length ? JSON.parse(localStorage.getItem("itemsList")) : state.items
  );
  useEffect(() => {
    console.log(state.tanksToOffer)
    console.log()
  if(!state.tanksToOffer.length){
    dispatch({
      type: "SET_ITEMS_LIST",
      data: itemsList.filter(item=>item.id !== 1),
    });
    setItemsList(itemsList.filter(item=>item.id !== 1))
  }

  }, [state.tanksToOffer])
  

  const handleSubmit = () => {
    let checkedItems = state.items.filter((item) => item.checked);
    if (!checkedItems.length) {
      showModalHandler();
      return;
    }
    const tanksAreSelected = checkedItems.some((item) => item.itemCode === "0");
    if (!tanksAreSelected) {
      dispatch({
        type: "SET_ITEMS_TO_ORDER",
        data: checkedItems.filter((item) => item.itemCode !== "0"),
      });
      localStorage.setItem("itemsToOrder", JSON.stringify(checkedItems.filter((item) => item.itemCode !== "0")));
    }
    navigate(tanksAreSelected ? "/amountpicker" : "/address");
  };
  const showModalHandler = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    dispatch({
      type: "SET_ITEMS_LIST",
      data: itemsList,
    });
    localStorage.setItem("itemsList", JSON.stringify(itemsList));
    dispatch({
      type: "SET_SELECTED_DELIVERY_DATE",
      data: null,
    });

    localStorage.removeItem("selectedDeliveryDate");
  }, [itemsList]);
  return (
    <PageTemplate title="Select the item(s) you would like to order">
      {itemsList.map((item) => {
        return <Item allItems={itemsList} setAllItems={setItemsList} item={item} key={`${item.id}-${item.title}`} />;
      })}
      <div id="buttons">
        <Button name="Submit" onClick={handleSubmit} style={{ background: "rgb(1, 34, 65)", color: "white" }} />
        <Button
          name="Back"
          onClick={() => {
            navigate(-1);
          }}
          style={{ background: "white", color: "rgb(1, 34, 65)" }}
        />
        <ExitModal show={showExitModal} handleClose={setShowExitModal}>
          <p style={{ color: "rgb(1  34 65)" }}>Are you sure you want to cancel?</p>
        </ExitModal>
      </div>
      <Modal show={showModal} handleClose={closeModal} closeButtonTitle="Okay">
        <p>Please select at least one item</p>
      </Modal>
    </PageTemplate>
  );
};
export default ItemSelectPage;
