export const createValidateApi = (fetchApi) => {
  function checkOneTimeLink(oneTimeCode) {

    const token = localStorage.getItem('token') || ""
    const refreshKey = localStorage.getItem('refreshKey') || ""

    return fetchApi(`/OneTimeLink/${oneTimeCode}`, "POST", {
      refreshKey: (token.length && refreshKey.length) ? refreshKey : ""
    });
  }

  function validateDobZip(data) {
    return fetchApi(`/DobZip`, "POST", data);
  }
  function validateDobZipAllowed(oneTimeCode) {
    return fetchApi(`/DobAllowed?oneTimeCode=${oneTimeCode}`, "GET");
  }
  function getResendSmsInfo() {
    return fetchApi(`/ResendSmsInfo`, "GET");
  }

  return {
    checkOneTimeLink,
    validateDobZip,
    validateDobZipAllowed,
    getResendSmsInfo,
  };
};
