import './Container.css';
const Container = ({children, style, handleDate}) => {

  return (   
    <div className='form-elements' onClick={handleDate} style={{ position: 'relative'}}>
      <div className='form-element flex' style={style}>
        {children}
      </div>
    </div>
  )
} 
export default Container;