import "./Modal.css";

const Modal = ({ handleClose, show, children, closeButtonTitle, confirmButtonTitle = false, confirmAction = () => {} }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <div style={{ display: "flex", width: "100%", justifyContent: "center", padding: "30px 0px 0px 0px", color: "rgb(1, 34, 65)" }}>{children}</div>
        {confirmButtonTitle && (
          <div
            type="button"
            id="button"
            onClick={() => {
              confirmAction();
              handleClose();
            }}
          >
            {confirmButtonTitle}
          </div>
        )}
        <div type="button" id="button" onClick={handleClose}>
          {closeButtonTitle}
        </div>
      </section>
    </div>
  );
};
export default Modal;
