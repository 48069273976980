import "./ChangeAddress.css";
import Container from "../../components/container/Container";
import Input from "../../components/Input";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { ProcessFlowContext } from "../../context/context";
import Button from "../../components/button/Button";
import PageTemplate from "../../components/PageTemplate";
import { api } from "../../services";
import Autocomplete from "react-google-autocomplete";
import { LoadingSpinner } from "../../components/loadingSpinner/LoadingSpinner";
import { refreshToken } from "../../hooks/TokenIsExpired";

const inputStyle = {
  border: " 0px",
  width: "100%",
  fontSize: "large",
  textAlign: "center",
  background: "white",
};
const ChangeAddressPage = () => {
  const navigate = useNavigate();
  const { state, dispatch } = React.useContext(ProcessFlowContext);
  const [validateStreet, setValidateStreet] = useState(true);
  const [validateApartment, setValidateApartment] = useState(true);
  const [validateCity, setValidateCity] = useState(true);
  const [validateUserState, setValidateUserState] = useState(true);
  const [validateZipCode, setValidateZipCode] = useState(true);
  const [street, setStreet] = useState(state.street || JSON.parse(localStorage.getItem("street")) || "");
  const [apartment, setApartment] = useState(state.apartment || JSON.parse(localStorage.getItem("apartment")) || "");
  const [city, setCity] = useState(state.city || JSON.parse(localStorage.getItem("city")) || "");
  const [userState, setUserState] = useState(state.userState || JSON.parse(localStorage.getItem("state")) || "");
  const [zipCode, setZipCode] = useState(state.zipCode || JSON.parse(localStorage.getItem("zipCode")) || "");
  const [pageLoaded, setPageLoaded] = useState(true);
  const streetRegex = new RegExp(/^[a-zA-Z0-9\s.'&,()\/;:\[\]-]+$/);
  const cityRegex = new RegExp(/^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/);
  const stateRegex = new RegExp(
    /^(AL|AK|AZ|AR|CA|CO|CT|DE|FL|GA|HI|ID|IL|IN|IA|KS|KY|LA|ME|MD|MA|MI|MN|MS|MO|MT|NE|NV|NH|NJ|NM|NY|NC|ND|OH|OK|OR|PA|RI|SC|SD|TN|TX|UT|VT|VA|WA|WV|WI|WY)$/i
  );
  const numberRegex = new RegExp("^[0-9]*$");
  const [streetNumberError, setStreetNumberError] = useState(false);
  const [googleApiKey, setGoogleApiKey] = useState("");

  const handleSubmit = () => {
    if(streetNumberError) return
    if (!streetRegex.test(street)) {
      setValidateStreet(false);
      return;
    } else {
      dispatch({
        type: "SET_STREET",
        data: street,
      });
      setValidateStreet(true);
    }
    if (apartment.length) {
      if (!streetRegex.test(apartment)) {
        setValidateApartment(false);
        return;
      } else {
        dispatch({
          type: "SET_APARTMENT",
          data: apartment,
        });
        setValidateApartment(true);
      }
    } else {
      dispatch({
        type: "SET_APARTMENT",
        data: "",
      });
    }
    if (!cityRegex.test(city)) {
      setValidateCity(false);
      return;
    } else {
      dispatch({
        type: "SET_CITY",
        data: city,
      });
      setValidateCity(true);
    }
    if (!stateRegex.test(userState)) {
      setValidateUserState(false);
      return;
    } else {
      dispatch({
        type: "SET_USERSTATE",
        data: userState,
      });
      setValidateUserState(true);
    }
    if (zipCode.length !== 5 || !numberRegex.test(zipCode) || zipCode === "00000") {
      setValidateZipCode(false);
      return;
    } else {
      dispatch({
        type: "SET_ZIPCODE",
        data: zipCode,
      });
      setValidateZipCode(true);
    }
    const tanksAreSelected = !!state.itemsToOrder && state.itemsToOrder.find((item) => item.isTank);
    if (tanksAreSelected) {
      setPageLoaded(false);
      refreshToken(
        ()=> api.address
          .checkNewAddress({
            oneTimeCode: state.oneTimeString || JSON.parse(localStorage.getItem("oneTimeString")),
            street,
            apartment,
            city,
            state: userState,
            zipCode: zipCode,
          })
          .then((res) => {
            if (!res) {
              return;
            }
            if (!res.success) {
              dispatch({
                type: "SET_ERRORMESSAGE",
                data: res.data.Detail,
              });
              navigate("/message", {
                state: {
                  buttonProps: [
                    // { name: "Submit", nextPage: "/emailToForeignBranches", styles: { background: "rgb(1, 34, 65)", color: "white" } },
                    { name: "Back", nextPage: -1, styles: { background: "white", color: "rgb(1, 34, 65)" } },
                  ],
                },
              });
              return;
            } else {
              localStorage.setItem("zipCode", JSON.stringify(zipCode));
              localStorage.setItem("street", JSON.stringify(street));
              localStorage.setItem("apartment", JSON.stringify(apartment));
              localStorage.setItem("city", JSON.stringify(city));
              localStorage.setItem("state", JSON.stringify(userState));
              navigate(state.addressWidgetLastVisitedPage || "/address");
              setPageLoaded(true);
            }
          })
      );
    } else {
      localStorage.setItem("zipCode", JSON.stringify(zipCode));
      localStorage.setItem("street", JSON.stringify(street));
      localStorage.setItem("apartment", JSON.stringify(apartment));
      localStorage.setItem("city", JSON.stringify(city));
      localStorage.setItem("state", JSON.stringify(userState));
      setPageLoaded(true);
      navigate(state.addressWidgetLastVisitedPage || "/address");
    }
  };
  useEffect(() => {
    refreshToken(
      ()=> api.address.getGoogleAPIKey().then((res) => {
        setGoogleApiKey(res.data.key);
      })
    );
  }, []);

  useEffect(() => {
    !!street && street.length && setValidateStreet(streetRegex.test(street));
  }, [street]);

  useEffect(() => {
    if (!apartment.length) {
      setValidateApartment(true);
      return;
    }
    setValidateApartment(streetRegex.test(apartment));
  }, [apartment]);

  useEffect(() => {
    city.length && setValidateCity(cityRegex.test(city));
  }, [city]);
  useEffect(() => {
    userState.length && setValidateUserState(stateRegex.test(userState));
  }, [userState]);
  useEffect(() => {
    if (!numberRegex.test(zipCode) || zipCode === "00000") {
      !!zipCode && zipCode.length && setValidateZipCode(false);
    } else {
      setValidateZipCode(true);
    }
  }, [zipCode]);

  const onChangeInputApartment = (e) => {
    const { value } = e.target;
    setValidateApartment(true);
    setApartment(value);
  };
  const approveSubmit = () => {
    if (!street) return false;
    if (!city) return false;
    if (!userState) return false;
    if (!zipCode) return false;
    if (zipCode.length !== 5) return false;
    if (zipCode === "00000") return false;
    if (streetNumberError) return false;
    return true;
  };
  return !pageLoaded ? (
    <LoadingSpinner />
  ) : (
    <PageTemplate title="Enter the new shipping address:">
      {googleApiKey.length ? (
        <Autocomplete
          style={{ width: "calc(90% - 20px)", margin: "0 5%", fontSize: 24, padding: "10px", borderRadius: "5px" }}
          placeholder="Search for your address"
          apiKey={googleApiKey}
          options={{
            types: ["address"],
            componentRestrictions: { country: "us" },
            fields: ["formatted_address", "address_components"],
          }}
          onPlaceSelected={(place) => {
            if (place && place.formatted_address) {
              const fullAddress = place && place.formatted_address;
              place.address_components[0].types.includes("street_number") ? setStreetNumberError(false) : setStreetNumberError(true);

              setStreet(fullAddress.split(",")[0].trim());
              setCity(fullAddress.split(",")[1].trim());
              const stateAndZipCode = fullAddress.split(",")[2].trim().split(" ");
              setUserState((place.address_components.find((component) => component.short_name === stateAndZipCode[0]) || { long_name: "" }).short_name || "");
              setZipCode(stateAndZipCode[1] && stateAndZipCode[1].length ? stateAndZipCode[1] : "");
            }
          }}
        />
      ) : (
        <></>
      )}
      {streetNumberError && <p style={{ color: "red", marginLeft: "5%" }}>Please enter the street number.</p>}
      <form className="form-container" onSubmit={handleSubmit}>
        <Container style={{ margin: 0, flexDirection: "column", height: "70px" }}>
          <>
            <Input placeholder="Appartment/Suite/Unit number" style={inputStyle} disabled />
            <input placeholder="####" style={{ ...inputStyle, marginTop: "20px" }} onChange={onChangeInputApartment} value={apartment} />
            {!validateApartment && <p className="errorMessageAddress">Not a valid apartment</p>}
          </>
        </Container>
        <div style={{ marginTop: "10px", padding: "0 20px", fontWeight: "bold", color: "rgb(1, 34, 65", fontSize: "24px" }}>
          Full Address:
          <p style={{ fontSize: "18px" }}>
            {street}, {apartment}
          </p>
          <p style={{ fontSize: "18px" }}>
            {city}, {userState}, {zipCode}
          </p>
        </div>
      </form>
      <div id="updateButtons">
        <Button
          name="Back"
          onClick={() => {
            navigate(-1);
          }}
          style={{ background: "white", color: "rgb(1, 34, 65)", flex: "1" }}
        />
        <Button
          name="Submit"
          onClick={handleSubmit}
          style={{ background: "rgb(1, 34, 65)", color: "white", opacity: `${approveSubmit() ? "1" : "0.6"}`, flex: "1" }}
        />
      </div>
    </PageTemplate>
  );
};
export default ChangeAddressPage;
