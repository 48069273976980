import { useState } from "react";
import { useNavigate } from "react-router";
import * as React from "react";
import { ProcessFlowContext } from "../../context/context";
import Button from "../../components/button/Button";
import Modal from "../../components/modal/Modal";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers";


const DatePickerPage = () => {
  const { state, dispatch } = React.useContext(ProcessFlowContext);
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(state.date || dayjs('1940-01-01'));

  const handleDateChange = (date) => {

    setSelectedDate(date);
    dispatch({
      type: "SET_DATE",
      data: date,
    });
    localStorage.setItem("date", JSON.stringify(date));
  };
  const isDatePicked = () => {
    if (state.date) return true;
    return false;
  };
  const [showModal, setShowModal] = useState(false);
  const showModalHandler = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <>
      <div style={{ display: "flex", justifyContent: "center", margin: "30px", flexDirection: "column" }}>
        <h1 style={{fontSize:"24px" ,margin:"10px 0 30px 0", color: "#012241"}}>Select your date of birth</h1>
        <div style={{ margin: "10px", fontSize: "10px", color: "grey" }}>mm/dd/yyyy</div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StaticDatePicker
            defaultValue={selectedDate ? dayjs(selectedDate): dayjs("1940-04-1")}
            openTo="year"
            maxDate={dayjs()}
            minDate={dayjs('1900-01-01')}
            onChange={(date) => {
            handleDateChange(date)
            }}
            onAccept={() => {
              isDatePicked() ? navigate("/home") : showModalHandler();

            }}
            slotProps={{
              textField: {
                helperText: "MM/DD/YYYY",
              },
              actionBar: {
                actions: ['accept'],
              },
            }}
          />
        </LocalizationProvider>
        <div>
        </div>
        <Modal show={showModal} handleClose={closeModal} closeButtonTitle="Okay">
          <p>Please select date</p>
        </Modal>
      </div>
    </>
  );
};
export default DatePickerPage;
