import { useContext, useEffect, useState } from "react";
import PageTemplate from "../../components/PageTemplate";
import { AddressWidget } from "../../components/addressWidget/AddressWidget";
import { ProcessFlowContext } from "../../context/context";
import "./cylinderChoice.css";
import Button from "../../components/button/Button";
import { useNavigate } from "react-router-dom";
import { LoadingSpinner } from "../../components/loadingSpinner/LoadingSpinner";

export const CylinderChoice = () => {
  const { state, dispatch } = useContext(ProcessFlowContext);
  const [selectedTanks, setSelectedTanks] = useState([]);
  const tanksToOffer = state.tanksToOffer.length ? state.tanksToOffer : JSON.parse(localStorage.getItem("tanksToOffer"));
  const navigate = useNavigate();

  const handleSubmit = () => {
    if (!selectedTanks.length) return;
    const currentItems = [...state.items];
    currentItems.shift();
    const itemsToOrder = [
      ...currentItems,
      ...selectedTanks.map((tank) => ({
        id: 1,
        itemCode: tank,
        title: "Oxygen cylinders",
        checked: true,
        quantityFull: 0,
        quantityEmpty: 0,
        isTank: true,
      })),
    ];
    dispatch({
      type: "SET_ITEMS_TO_ORDER",
      data: itemsToOrder,
    });
    localStorage.setItem("itemsToOrder", JSON.stringify(itemsToOrder));
    navigate("/amountpicker");
  };

  useEffect(() => {
    if (tanksToOffer.length === 1) {
      setSelectedTanks([tanksToOffer[0]]);
    }
  }, []);

  const choices = tanksToOffer.map((tank, index) => {
    return (
      <div
        key={tank}
        onClick={() => {
          if (selectedTanks.includes(tank)) {
            setSelectedTanks((prev) => [...prev.filter((t) => t !== tank)]);
            return;
          }
          setSelectedTanks((prev) => [...prev, tank]);
        }}
        style={{
          ...(selectedTanks.includes(tank) ? { border: "3px solid rgb(1, 34, 65)" } : { border: "3px solid transparent" }),
          flex: 1,
          backgroundColor: "white",
          borderRadius: "20px",
          padding: "5px",
          boxSizing: "border-box",
        }}
      >
        <img className="cylinder" src={require(`../../../public/cylinders/${tank}.png`)} />
      </div>
    );
  });
  return (
    <>
      {/* <AddressWidget /> */}
      <PageTemplate title="Select the cylinders you would like to order">
        <div className="cylinderContainer">{choices}</div>
        <Button
          name="Submit"
          onClick={handleSubmit}
          style={{
            marginTop: " 50px",
            ...(selectedTanks.length ? { background: "rgba(1, 34, 65, 1)" } : { background: "rgba(1, 34, 65, 0.6)" }),
            color: "white",
          }}
        />
        <Button
          name="Back"
          onClick={() => {
            navigate(-1);
          }}
          style={{ background: "white", color: "rgb(1, 34, 65)", flex: "1" }}
        />
      </PageTemplate>
    </>
  );
};
