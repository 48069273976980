import { useContext } from "react";
import logo from "../assets/images/Lincare_White.png";
import { ProcessFlowContext } from "../context/context";

const Header = () => {
  const { state } = useContext(ProcessFlowContext);
  return (
    <div id="toolbar" style={{ display: "flex", justifyContent: "center" }}>
      <div id="logo">
        {state.dbaName || localStorage.getItem("dbaName") || ''}
        {/* <img src={logo} alt="logo"/> */}
      </div>
    </div>
  );
};
export default Header;
