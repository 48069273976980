import './Button.css';
import React from 'react';

const Button = ({onClick, name, style, buttonLoading = false}) => {

  return (
    <div className='button' onClick={!buttonLoading && onClick} style={style}>
      {name}
    </div>
  )
} 
export default Button;