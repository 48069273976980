import "./LoadingSpinner.css"

export const LoadingSpinner = () => {
  return (
    <div className="spinnerContainer">
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
