export const createAddressApi = (fetchApi) => {
  function checkNewAddress(data) {
    return fetchApi(`/NewShippingAddress?oneTimeCode=${data.oneTimeCode}`, "POST", data);
  }
  function sendEmailToForeignBranches(data, oneTimeCode) {
    return fetchApi(`/SendEmailToForeignBranches?oneTimeCode=${oneTimeCode}`, "POST", data);
  }

  function getGoogleAPIKey() {
    return fetchApi(`/GoogleApiKey`, "GET");
  }

  return {
    checkNewAddress,
    getGoogleAPIKey,
    sendEmailToForeignBranches
  };
};
