import jwt_decode from "jwt-decode";
import { retryAuth } from "./RetryAuth";

const TokenIsExpired = () => {
  const token = localStorage.getItem("token");
  if (!token || !token.length) return true;
  const tokenExpiry = jwt_decode(token).exp * 1000;
  if (Date.now() > tokenExpiry) return true;
  return false;
};

export const refreshToken = (next) => {
    if (TokenIsExpired()) {
        retryAuth().then(res=>next())
    }else{
        next()
    }
}