import { useNavigate } from "react-router-dom";
import "./ExitModal.css";
import React from "react";
import { ProcessFlowContext } from "../../../context/context";
import { message } from "../../../hooks/ErrorMessage";
import { api } from "../../../services";

const ExitModal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";
  const navigate = useNavigate();
  const { state, dispatch } = React.useContext(ProcessFlowContext);
  const exit = () => {
    api.message.getMessage('cancel').then((res) => {
      if (!res) {
        return;
      }
      if (!res.success) {
        dispatch({
          type: "SET_ERRORMESSAGE",
          data: res.data.Detail,
        });
        navigate("/message", {
          state: {
            buttonProps: [{ name: "Back", nextPage: -1, styles: { background: "white", color: "rgb(1, 34, 65)" } }],
          },
        });
        return;
      } else {
        dispatch({
          type: "SET_ERRORMESSAGE",
          data: res.data.message,
        });

        dispatch({
          type: "SET_VALIDATEINFO",
          data: false,
        });

        dispatch({
          type: "SET_ONE_TIME_STRING",
          data: null,
        });

        localStorage.removeItem("date");
        localStorage.removeItem("zipCode");
        localStorage.removeItem("street");
        localStorage.removeItem("city");
        localStorage.removeItem("state");
        localStorage.removeItem("tanksToOffer");
        localStorage.removeItem("oneTimeString");
        localStorage.removeItem("token");
        localStorage.removeItem("refreshKey");

        navigate("/message");
      }
    });
  };
  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <div style={{ display: "flex", width: "100%", justifyContent: "center", padding: "20px 0px 5px 0px", fontSize: "23px" }}>{children}</div>
        <div style={{ display: "flex", borderTop: "1px solid rgba(8, 0, 0, .1)" }}>
          <div style={{ flex: "1", textAlign: "center", color: "red", padding: "10px 0px" }} type="button" onClick={handleClose}>
            No
          </div>
          <div onClick={exit} type="button" style={{ borderLeft: "2px solid rgba(8, 0, 0, .1)", flex: "1", textAlign: "center", padding: "10px 0px" }}>
            Yes, Cancel
          </div>
        </div>
      </section>
    </div>
  );
};
export default ExitModal;
