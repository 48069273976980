import { REACT_APP_API_VERSION, getApiUrlConfigVariable } from "../../config";

async function call(url, type, data) {
  const API_URL = await getApiUrlConfigVariable()
  let success = true;
  let params = {
    method: type,
    headers: {
      "Content-Type": "application/json",
      ...(!!localStorage.getItem("token") && localStorage.getItem("token").length && { Authorization: `Bearer ${localStorage.getItem("token")}` }),
    },
  };
  if (data) params.body = JSON.stringify(data);
  return fetch(`${API_URL}${REACT_APP_API_VERSION}${url}`, params)
    .then((response) => {
      if (!response.ok) {
        if (response.status > 499 && response.status < 600) {
          throw new Error("Server Error", { cause: "server_error" });
        }
        success = false;
      }
      return response.json();
    })
    .then((data) => {
      try {
        data = JSON.parse(data);
      } catch (error) {}
      return {
        success,
        data,
      };
    })
    .catch((err) => {
      if (err.cause === "server_error") window.location = "/#/something-went-wrong";
    });
}

export const fetchApi = (url, type, data = null) => {

  return call(url, type, data);
};
