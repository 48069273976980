import PageTemplate from "../../components/PageTemplate";
import Container from "../../components/container/Container";
import React, { useEffect } from "react";
import { ProcessFlowContext } from "../../context/context";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/button/Button";
const ErrorPage = () => {
  const { state } = React.useContext(ProcessFlowContext);
  const navigate = useNavigate();
  const location = useLocation();
  const buttonProps = location.state && location.state.buttonProps;

  useEffect(() => {
    if (!state.errorMessage) {
      navigate("/-");
    }
    // localStorage.clear();
  }, []);
  
  return (
    <PageTemplate>
      <Container style={{ justifyContent: "center" }}>
        <p style={{ textAlign: "center" }}>{state.errorMessage || ""}</p>
      </Container>
      <div style={{ marginTop: "40px" }}>
        {buttonProps &&
          buttonProps.length &&
          buttonProps.map((button) => (
            <Button
              name={button.name}
              onClick={() => {
                !!button.external ? window.open(button.nextPage) : navigate(button.nextPage);
              }}
              style={button.styles}
            />
          ))}
      </div>
    </PageTemplate>
  );
};
export default ErrorPage;
