import PageTemplate from '../../components/PageTemplate';
import Container from '../../components/container/Container';
import React, { useEffect } from 'react';
import Button from '../../components/button/Button';
import { useNavigate } from 'react-router-dom';
import { api } from '../../services';
import { ProcessFlowContext } from '../../context/context';
const NotFound = () => {
  const navigate = useNavigate();
  const { state } = React.useContext(ProcessFlowContext);

  useEffect(() => {
    api.home.checkOneTimeLink(state.oneTimeString).then((res) => {
      if(!res.data) {
       localStorage.clear();
      } 
    })
  })
  return (
    <PageTemplate>
      <Container style={{justifyContent: 'center', textAlign: 'center'}}>
        <p>You don't have access to this page.</p>
      </Container>
      <Button name='Go to home page' onClick={() => {navigate(`/home?user_code=${state.oneTimeString || JSON.parse(localStorage.getItem("oneTimeString"))}`)}} style={{background: 'rgb(1, 34, 65)', color: 'white', margin: '30px'}}/>
    </PageTemplate>
  )
} 
export default NotFound;